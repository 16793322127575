<template>
<div>
    <v-data-table  disable-sort :loading="loadingTable" :headers="headers" :items="checkouts"  class="elevation-1" :page-count="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15,50],
        showFirstLastPage: true,
      }">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Checkouts</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                 <v-badge :content="bagFilter" :value="bagFilter" bordered color="error" overlap>
                  <v-btn  large outlined rounded color="primary" @click="dialogFilter=true"><v-icon>{{icons.mdiFilter }}</v-icon>filtros</v-btn>
                </v-badge>
                <v-spacer></v-spacer>
            </v-toolbar>
        </template>

        <template v-slot:item.id="{ item }">
            <div style="font-size: 18px">{{ item.id }}</div>
        </template>
        <template v-slot:item.user="{ item }">
            <div style="font-size: 18px">{{ item.user.name }}</div>
        </template>
        <template v-slot:item.date_sale="{ item }">
            <div style="font-size: 18px">{{ formatDateTime(item.date_sale) }}</div>
        </template>
        
        <template v-slot:item.total="{ item }">
            <div style="font-size: 18px">{{ formatMoney(item.total) }}</div>
        </template>

        <template v-slot:item.room="{ item }">
          <div style="font-size: 18px">{{ item.accommodation.room.description }}</div>
        </template>
        
        <template v-slot:item.guests="{ item }">
            <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <span style="font-size: 18px" v-bind="attrs" v-on="on">{{ item.accommodation.guest[0].name }}</span>
      </template>
      <span style="font-size: 18px">{{joinGuest( item )}}</span>
    </v-tooltip>
            
        </template>

        <template v-slot:item.actions="{ item }">
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-if="item.note" color="primary" class="mr-2" @click="showNote(item)" v-bind="attrs" v-on="on">
                    {{ icons.mdiNoteOutline   }}
                </v-icon>
            </template>
            <span>Nota</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :loading="item.loading_print" :disabled="item.loading_print"  color="secondary" @click="exportPdf(item)" v-bind="attrs" v-on="on">
                  <v-icon >
                    {{ icons.mdiPrinter  }}
                </v-icon>
                </v-btn>
                
              </template>
            <span>Imprimir</span>
            </v-tooltip>

            <!-- <v-icon color="primary" class="mr-2" @click="editItem(item)">
                {{ icons.mdiPencilOutline }}
            </v-icon>
            <v-icon color="error" @click="deleteItem(item)">
                {{ icons.mdiDeleteOutline }}
            </v-icon>  -->
        </template>
        <template v-slot:no-data>
            <NoDataTable></NoDataTable>
        </template>
        <template v-slot:loading>
            Carregando...
        </template>
    </v-data-table>
    
    <v-dialog v-model="dialogNote" width="600">
      <v-card>
        <BarTitleDialog title="NOTA" @close="dialogNote=false" />
        <v-card-text>
          <pre>{{ note }}</pre>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn x-large color="secondary" @click="dialogNote=false">fechar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- DIALOG FILTRO -->
     <v-dialog v-model="dialogFilter" width="700">
      <v-card>
        <BarTitleDialog title="FILTROS" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="filter.checkout_date" outlined type="date" label="Data Checkout"></v-text-field>
            </v-col>
          </v-row>
         </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn @click="confirmFilter()" color="primary" class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
    
</div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import { mdiPrinter, mdiFilter } from '@mdi/js'
import { mapActions } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'

export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog,
  },
  data: () => ({
    // FILTER
    filter: {},
    dialogFilter: false,
    note: '',
    dialogNote: false,
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      {
        text: 'DATA CHECKOUT',
        align: 'start',
        value: 'date_sale',
      },
      {
        text: 'QUARTO',
        align: 'start',
        value: 'room',
      },
      {
        text: 'VALOR',
        value: 'total',
      },
      {
        text: 'USUÁRIO',
        align: 'start',
        value: 'user',
      },
      {
        text: 'HÓSPEDES',
        align: 'start',
        value: 'guests',
      },
      {
        text: '',
        align: 'end',
        value: 'actions',
        sortable: false,
      },
    ],
    checkouts: [],
    editedIndex: -1,
    editedItem: {
      description: '',
    },
    defaultItem: {
      description: '',
    },
    options: {
      itemsPerPage: 50,
      page: 1,
    },
    icons: {
      mdiPrinter,
      mdiFilter,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Novo Pix' : 'Editar Pix'
    },
    bagFilter() {
      return Object.keys(this.filter).length
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },

    options: {
      handler() {
        this.initialize()
      },
    },
  },

  created() {
    // this.getCategory().then(response => {
    //   this.categoryItems = response.data
    // })

    this.initialize()
  },

  methods: {
    ...mapActions('manager_checkouts', ['getAll', 'printCheckoutPdf']),

    initialize() {
      this.loadingTable = true
      const { page, itemsPerPage } = this.options
      const payload = {
        itemsPerPage: itemsPerPage || 10,
        page,
        search: this.search,
        filter: this.filter,
      }

      this.getAll(payload)
        .then(response => {
          this.checkouts = response.data.data
          this.totalStagesPaginate = response.data.total
          this.numberOfPagesPaginate = response.data.last_page
          this.currentPagePaginate = response.data.current_page
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    joinGuest(checkout) {
      if (checkout.accommodation) {
        let guests = checkout.accommodation.guest
        let guestsName = []
        guests.forEach(guest => {
          guestsName.push(guest.name)
        })
        return guestsName.join(', ')
      }
    },
    showNote(item) {
      this.dialogNote = true
      this.note = item.note
    },
    // calcPayment(item) {
    //   const sum_payment = item.payment.reduce((acc, payment) => {
    //     return (
    //       acc +
    //       (parseFloat(payment.subtotal) +
    //         parseFloat(payment.tax) +
    //         parseFloat(payment.additional) -
    //         parseFloat(payment.discount))
    //     )
    //   }, 0)

    //   return this.formatMoney(sum_payment)
    // },
    clearFilter() {
      this.filter.checkout_date = ''
    },
    confirmFilter() {
      this.initialize()
      this.dialogFilter = false
    },
    formatMoney(value) {
      value = parseFloat(value)
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    formatDateTime(date) {
      if (date) {
        const result = new Date(date)
        return (
          result.toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
          }) +
          ' ' +
          result.toLocaleTimeString('pt-BR')
        )
      }
    },
    exportPdf(checkout) {
      checkout.loading_print = true
      this.printCheckoutPdf(checkout)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'voucher.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {
          this.checkouts.forEach(item => {
            item.loading_print = false
          })
          //armegagem para força a reação do vue
          let backup = this.checkouts
          this.checkouts = []
          this.checkouts = backup
        })
    },
  },
}
</script>
